import React, { useState } from 'react';
import './App.css';
import AnimatedText from './components/AnimatedText';
import { Select } from 'antd';

const { Option, OptGroup } = Select;

function App() {

  const [animation, setanimation] = useState('')

  const handleChange =(e)=> {
    setanimation(e)
  }
  return (
    <div className="App">
      <div style={{
        display:'flex',
        height: '70vh',
        width: '100%',
        justifyContent: 'space-evenly',
        alignItems:'center',
      }}>
        <Select defaultValue="choose animation" style={{ width: 200 }} onChange={handleChange}>
          <OptGroup label="Attention Seeker">
            <Option value="animate__bounce">Bounce</Option>
            <Option value="animate__flash">Flash</Option>
          </OptGroup>
          <OptGroup label="Specials">
            <Option value="animate__hinge">Hinge</Option>
            <Option value="animate__jackInTheBox">Jack in the Box</Option>
            <Option value="animate__rollIn">Roll In</Option>
            <Option value="animate__rollOut">ROll out</Option>

          </OptGroup>
        </Select>
        <AnimatedText animation={animation}/>
      </div>
    </div>
  );
}

export default App;
