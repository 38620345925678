import React from 'react'
import 'animate.css';
import './animatedtext.css'
export default function AnimatedText({animation}) {
    return (
        <div>
            <h1 className={`animate__animated ${animation}`}>Here's an animated text</h1>
        </div>
    )
}
